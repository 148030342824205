<template>
  <div class="storeSet">
    <div class="cell">
      <div class="title">商店设定</div>
      <div class="describe">更新卖场基础设定</div>
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本设置" name="1" v-if="basic">
            <div>
              <div style="display: flex; align-items: center">
                <div style="width: 4%; text-align: center">
                  <i
                    style="font-size: 28px; color: #9f9f9f"
                    class="el-icon-moon"
                  ></i>
                </div>
                <div
                  style="
                    display: flex;
                    width: 96%;
                    border-bottom: 1px solid #e5e5e5;
                    align-items: center;
                  "
                >
                  <div style="width: 96%">
                    <div style="font-size: 16px; padding-top: 8px">
                      休假模式
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        margin-top: 8px;
                        color: #999;
                        margin-bottom: 20px;
                      "
                    >
                      开启此选项，可避免买家成立新的订单，也请您记得处理现有的订单启动休假模式越需1小时系统作业时间。
                    </div>
                  </div>
                  <div style="width: 4%">
                    <el-switch
                      active-value="1"
                      inactive-value="0"
                      v-model="basic.value.vacation_mode"
                      @change="change(1)"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <div style="width: 4%; text-align: center">
                  <i
                    style="font-size: 28px; color: #999"
                    class="el-icon-basketball"
                  ></i>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 96%;
                  "
                >
                  <div style="margin: 20px 0; width: 60%">语言设定</div>
                  <div
                    style="
                      width: 16%;
                      display: flex;
                      align-items: center;
                      justify-content: right;
                    "
                  >
                    <el-radio-group
                      @change="change(2)"
                      v-model="basic.value.language"
                    >
                      <el-radio label="1">English</el-radio>
                      <el-radio label="0">简体中文</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="隐私设定" name="2" v-if="privacy">
            <div>
              <div style="display: flex; align-items: center">
                <div style="width: 4%; text-align: center">
                  <i
                    style="font-size: 28px; color: #9f9f9f"
                    class="el-icon-help"
                  ></i>
                </div>
                <div
                  style="
                    display: flex;
                    width: 96%;
                    border-bottom: 1px solid #e5e5e5;
                    align-items: center;
                  "
                >
                  <div style="width: 96%">
                    <div style="font-size: 16px; padding-top: 8px">
                      隐藏按赞的好物
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        margin-top: 8px;
                        color: #999;
                        margin-bottom: 20px;
                      "
                    >
                      隐藏您的按赞后，您的粉丝将看不到您说赞的好物。
                    </div>
                  </div>
                  <div style="width: 4%">
                    <el-switch
                      active-value="1"
                      inactive-value="0"
                      @change="change(3)"
                      v-model="privacy.value.click_like"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>

              <div style="display: flex; align-items: center">
                <div style="width: 4%; text-align: center">
                  <i
                    style="font-size: 28px; color: #9f9f9f"
                    class="el-icon-star-off"
                  ></i>
                </div>
                <div
                  style="
                    display: flex;
                    width: 96%;

                    align-items: center;
                  "
                >
                  <div style="width: 96%">
                    <div style="font-size: 16px; padding-top: 8px">
                      隐藏按赞
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        margin-top: 8px;
                        color: #999;
                        margin-bottom: 20px;
                      "
                    >
                      隐藏您的按赞后，其他人将看不到您的按赞好物。
                    </div>
                  </div>
                  <div style="width: 4%">
                    <el-switch
                      active-value="1"
                      inactive-value="0"
                      v-model="privacy.value.click"
                      @change="change(4)"
                    >
                    </el-switch>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通知设定" name="3" v-if="notice">
            <div>
              <div style="display: flex; align-items: center">
                <div style="width: 4%; text-align: center">
                  <i
                    style="font-size: 28px; color: #9f9f9f"
                    class="el-icon-message"
                  ></i>
                </div>
                <div
                  style="
                    display: flex;
                    width: 96%;
                    border-bottom: 1px solid #e5e5e5;
                    align-items: center;
                  "
                >
                  <div style="width: 90%">
                    <div style="font-size: 16px; padding-top: 8px">
                      电邮通知
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        margin-top: 8px;
                        color: #999;
                        margin-bottom: 20px;
                      "
                    >
                      设定何时收到Shopee的邮件通知。
                    </div>
                  </div>
                  <div
                    style="width: 10%; display: flex; justify-content: right"
                  >
                    <el-button
                      type="primary"
                      size="medium"
                      v-if="notice.value.email == 0"
                      @click="change(5)"
                      >开启电邮
                    </el-button>
                    <el-button v-else size="medium" plain @click="change(5)"
                      >关闭电邮
                    </el-button>
                  </div>
                </div>
              </div>
              <div style="margin-left: 40px; padding: 20px">
                <div
                  style="display: flex; align-items: center"
                  v-for="(item, i) in list"
                  :key="i"
                >
                  <div style="width: 4%; text-align: center">
                    <i
                      style="font-size: 28px; color: #9f9f9f"
                      :class="item.icon"
                    ></i>
                  </div>
                  <div
                    style="
                      display: flex;
                      width: 96%;
                      border-bottom: 1px solid #e5e5e5;
                      align-items: center;
                    "
                  >
                    <div style="width: 96%">
                      <div
                        style="
                          font-size: 16px;
                          padding-top: 8px;
                          margin-bottom: 10px;
                          line-height: 40px;
                        "
                      >
                        {{ item.name }}
                      </div>
                    </div>
                    <div style="width: 4%">
                      <el-switch
                        active-value="1"
                        inactive-value="0"
                        v-model="item.value"
                        @change="change(item.type)"
                      >
                      </el-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { shopConfigList, setShopConfig } from "@/api/shop.js";
export default {
  data() {
    return {
      activeName: "1",
      basic: "",
      privacy: "",
      notice: "",
      list: {
        order_update: {
          value: "0",
          name: "订单更新通知",
          icon: "el-icon-tickets",
          label: "order_update",
          type: 6,
        },
        subscribe_email: {
          value: "0",
          name: "订阅电子邮件",
          icon: "el-icon-present",
          label: "subscribe_email",
          type: 7,
        },
        goods_update: {
          value: "0",
          name: "商品更新通知",
          icon: "el-icon-phone-outline",
          label: "goods_update",
          type: 8,
        },
        personal_dynamic: {
          value: "0",
          name: "个人动态内容",
          icon: "el-icon-user",
          label: "personal_dynamic",
          type: 9,
        },
      },
      value: "",
      radio: "",
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      shopConfigList({
        shop_id: this.$store.state.shop_info.shop_info.id,
      }).then((res) => {
        if (res.code == 1) {
          this.basic = res.data[0];
          this.privacy = res.data[1];
          this.notice = res.data[2];
          Object.keys(this.notice.value).forEach((item) => {
            Object.keys(this.list).forEach((val) => {
              if ((item = val)) {
                this.list[val].value = this.notice.value[item];
              }
            });
          });
        }
      });
    },
    handleClick() {},
    change(type) {
      let data = {
        type: type,
        value:
          type == 1
            ? this.basic.value.vacation_mode
            : type == 2
            ? this.basic.value.language
            : type == 3
            ? this.privacy.value.click_like
            : type == 4
            ? this.privacy.value.click
            : type == 6
            ? this.list.order_update.value
            : type == 7
            ? this.list.subscribe_email.value
            : type == 8
            ? this.list.goods_update.value
            : type == 9
            ? this.list.personal_dynamic.value
            : "",
        id:
          type < 3
            ? this.basic.id
            : type < 5
            ? this.privacy.id
            : this.notice.id,
        shop_id: this.$store.state.shop_info.shop_info.id,
      };
      if (type == 5) {
        data.value = this.notice.value.email == 0 ? 1 : 0;
      }
      setShopConfig(data).then((res) => {
        if (res.code == 1) {
          this.getConfig();
        }
      });
    },
  },
};
</script>
<style lang="less" >
.storeSet {
  .cell {
    padding: 30px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);
    .title {
      font-size: 18px;
    }
    .describe {
      font-size: 14px;
      color: #999;
      margin-top: 10px;
    }
    .content {
      margin-top: 20px;
      .el-tabs__nav {
        height: 50px;
      }
      .el-tabs__active-bar {
        height: 4px;
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
      }
    }
  }
}
</style>